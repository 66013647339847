import { Injectable } from '@angular/core';

export enum ParsableDateFormat {
  'DD/MM/YYYY',
  'DD/MM/YYYY HH:mm'
}

export enum DateFormat {
  'YYYY-MM-DD',
  'DD/MM/YYYY HH:mm',
  'DD/MM/YYYY'
}

@Injectable({
  providedIn: 'root'
})
export class DateHelperService {

  public static getDifferenceInHours(date1: Date | null | undefined, date2: Date | null | undefined): number {
    const diff = (date2?.getTime() ?? 0) - (date1?.getTime() ?? 0);
    return diff / (1000 * 60 * 60);
  }

  public static getCompare(dateField: string, asc: boolean = true): (a: any, b: any) => number {
    return (a: any, b: any) => {
      return (asc ? 1 : -1) * ((new Date(a[dateField] ?? 0).getTime()) - (new Date(b[dateField] ?? 0).getTime()));
    };
  }

  public static shiftToUtcDate(date: Date | null | undefined): Date | null {
    if (!date || !this.isValidDateObject(date)) {
      return null;
    }

    return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds(), date.getMilliseconds()));
  }

  public static shiftToUtc(date: Date | null | undefined): string | null {
    return this.shiftToUtcDate(date)?.toISOString() ?? null;
  }

  public static stripTimezoneDate(date: Date | null | undefined): string | null {
    if (!date || !this.isValidDateObject(date)) {
      return null;
    }

    const isoString = this.shiftToUtc(date);
    return isoString?.slice(0, isoString.length - 1) || null;
  }

  public static getEndOfDay(date: Date | null | undefined): Date | null {
    if (!date || !this.isValidDateObject(date)) {
      return null;
    }

    const endOfDay = new Date(date);
    endOfDay.setHours(23, 59, 59, 999);
    return endOfDay;
  }

  public static parseDateString(date: string | null, format: ParsableDateFormat): Date | null {
    if (!date) {
      return null;
    }

    switch (format) {
      case ParsableDateFormat['DD/MM/YYYY']:
        const dateParts = date.split('/');
        if (dateParts.length === 3) {
          const year = parseInt(dateParts[2], 10);
          const month = parseInt(dateParts[1], 10) - 1;
          const day = parseInt(dateParts[0], 10);
          return new Date(year, month, day);
        }
        return null;
      case ParsableDateFormat['DD/MM/YYYY HH:mm']:
        const [datePart, timePart] = date.split(' ');
        if (datePart && timePart) {
          const dateParts = datePart.split('/');
          const timeParts = timePart.split(':');
          if (dateParts.length === 3 && timeParts.length === 2) {
            const year = parseInt(dateParts[2], 10);
            const month = parseInt(dateParts[1], 10) - 1;
            const day = parseInt(dateParts[0], 10);
            const hours = parseInt(timeParts[0], 10);
            const minutes = parseInt(timeParts[1], 10);
            return new Date(year, month, day, hours, minutes);
          }
        }
        return null;
      default:
        return null;
    }
  }

  public static format(date: Date | null | undefined, format: DateFormat): string | null {
    if (!date || !this.isValidDateObject(date)) {
      return null;
    }

    switch (format) {
      case DateFormat['YYYY-MM-DD']:
        return date.toISOString().split('T')[0];

      case DateFormat['DD/MM/YYYY HH:mm']:
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        return `${this.getDDMMYYYY(date)} ${hours}:${minutes}`;
      case DateFormat['DD/MM/YYYY']:
        return this.getDDMMYYYY(date);

      default:
        return null;
    }

  }

  private static getDDMMYYYY(date: Date) {
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  }

  public static isSameMinuteOrBefore(date: Date | null | undefined, refDate: Date | null | undefined): boolean {
    if (!date || !refDate || !this.isValidDateObject(date) || !this.isValidDateObject(refDate)) {
      return false;
    }
    let date1 = new Date(date);
    date1.setSeconds(0, 0);
    let date2 = new Date(refDate);
    date2.setSeconds(0, 0);
    return date1.getTime() <= date2.getTime();
  }

  public static isSameMinuteOrAfter(date: Date | null | undefined, refDate: Date | null | undefined): boolean {
    if (!date || !refDate || !this.isValidDateObject(date) || !this.isValidDateObject(refDate)) {
      return false;
    }
    let date1 = new Date(date);
    date1.setSeconds(0, 0);
    let date2 = new Date(refDate);
    date2.setSeconds(0, 0);
    return date1.getTime() >= date2.getTime();
  }

  public static getEtaProgress(eta: Date | string | null | undefined, etd: Date | string | null | undefined) {
    if (eta && etd) {
      const _eta = new Date(eta);
      const _etd = new Date(etd);
      const now = new Date();
      return (((now.getTime() - _etd.getTime()) / (_eta.getTime() - _etd.getTime())) * 100);
    }

    return null;
  }

  private static isValidDateObject(date: Date) {
    if (!date.getTime) {
      console.warn('Date is not a valid date object', date);
      return false;
    }
    return true;
  }
}
